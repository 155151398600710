import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DataState from "ContextAPI/DataContext";
import { Navigate } from 'react-router-dom';

// // import LandingPagev2 from "./Pages/LandingPagev2";
// import Email_Warmup_LandingPage from "Pages/Email_Warmup_LandingPage";
// import LoadingPagev1 from "./Pages/LoadingPagev1";
// import PricingPagev2 from "./Pages/PricingPagev2";
// import Blogpagev3_Programatic_Seo from "./Pages/Blogpagev3_Programatic_Seo";
// import Why_is_my_mailchimp_email_going_to_spam from "./Pages/Blogs/Why_is_my_mailchimp_email_going_to_spam";
// // import Dashboardv2 from "./Pages/Dashboardv2";
// import EmailSpamChecker from "./Pages/EmailSpamChecker";
// // import DashboardView from "Pages/Dashboardv2/DashboardPage";
// import Dashboardv3 from "Pages/Dashboardv3";
// // import Login from "Pages/Login";
// import Loginv2 from "Pages/Loginv2";
// import AppPassword from "drawers/AppPassword";
// import AppPasswordv2 from "drawers/AppPasswordv2";
// // import LandingPagev2_AutomatedEmailWarmup from "Pages/LandingPagev2_AutomatedEmailWarmup";
// // import LandingPagev2_EmailWarmup from "Pages/LandingPagev2_EmailWarmup";
// // import GoogleSignup_API from "Pages/Auth/GoogleSignup_API";
// // import GoogleAccountAccess from "Pages/Auth/GoogleAccountAccess";
// import EmailConnectStatusPage from "Pages/Auth/EmailConnectStatusPage";
// import PrivacyPolicy from "Pages/PrivacyPolicy";
// import EmailList from "Pages/PSEO/EmailList";

const LandingPagev2_EmailWarmup = lazy(() =>
  import("./Pages/LandingPagev2_EmailWarmup")
);
const LandingPagev2_AutomatedEmailWarmup = lazy(() => import("./Pages/LandingPagev2_AutomatedEmailWarmup"))
const LandingPageNewEmailwarmup = lazy(() =>
  import("./Pages/LandingPageNewEmailwarmup")
);
const LandingPagev2_AutomatedEmailWarmup_Consultant = lazy(() => import("./Pages/LandingPagev2_AutomatedEmailWarmup_Consultant"))
const LandingPagev2_EmailWarmup_Consultant = lazy(() => import("./Pages/LandingPagev2_EmailWarmup_Consultant"))
const LandingPageNewAutoEmail = lazy(() =>
  import("./Pages/LandingPageNewAutoEmail")
);
const LandingPageNewAutoEmailBuffer = lazy(() =>
  import("./Pages/LandingPageNewAutoEmailBuffer")
);
// const HomeProcessing = lazy(() => import("../src/Pages/HomeProcessing"));
// const ExtensionLandingPage = lazy(() =>
//   import("../src/Pages/ExtensionLandingPage")
// );
// const LandingPagev2 = lazy(() => import("../src/Pages/LandingPagev2"));
const Email_Warmup_LandingPage = lazy(() =>
  import("Pages/Email_Warmup_LandingPage")
);
const AutoEmail_Warmup_LandingPage = lazy(() => import("Pages/AutoEmail_Warmup_LandingPage"))
const LoadingPagev1 = lazy(() => import("../src/Pages/LoadingPagev1"));
const PricingPagev2 = lazy(() => import("../src/Pages/PricingPagev2"));
const Blogpagev3_Programatic_Seo = lazy(() =>
  import("../src/Pages/Blogpagev3_Programatic_Seo")
);
const Why_is_my_mailchimp_email_going_to_spam = lazy(() =>
  import("../src/Pages/Blogs/Why_is_my_mailchimp_email_going_to_spam")
);
// const Dashboardv2 = lazy(() => import("../src/Pages/Dashboardv2"));
const EmailSpamChecker = lazy(() => import("../src/Pages/EmailSpamChecker"));
// const DashboardView = lazy(() => import("Pages/Dashboardv2/DashboardPage"));
const Dashboardv3 = lazy(() => import("Pages/Dashboardv3"));
// const Login = lazy(() => import("Pages/Login"));
const Loginv2 = lazy(() => import("Pages/Loginv2"));
const AppPassword = lazy(() => import("drawers/AppPassword"));
const AppPasswordv2 = lazy(() => import("drawers/AppPasswordv2"));
// const GoogleSignup_API = lazy(() => import("Pages/Auth/GoogleSignup_API"));
// const GoogleAccountAccess = lazy(() =>
//   import("Pages/Auth/GoogleAccountAccess")
// );
const EmailConnectStatusPage = lazy(() =>
  import("Pages/Auth/EmailConnectStatusPage")
);
const PrivacyPolicy = lazy(() => import("Pages/PrivacyPolicy"));
const EmailList = lazy(() => import("Pages/PSEO/EmailList"));
const EmailDeliverPage1 = lazy(() => import("Pages/EmailDeliverabilityTest/EmailDeliverPage1"))
const EmailDeliverPage2 = lazy(() => import("Pages/EmailDeliverabilityTest/EmailDeliverPage2"))
const EmailDeliverPage3 = lazy(() => import("Pages/EmailDeliverabilityTest/EmailDeliverPage3"))

// Pages and other components
// const HomeProcessing = React.lazy(() => import("./Pages/HomeProcessing"));
// const ExtensionLandingPage = React.lazy(() =>
//   import("./Pages/ExtensionLandingPage")
// );

const ProjectRoutes = () => {
  const importantCountries = [
    "usa",
    "canada",
    "uk",
    "australia",
    "germany",
    "france",
    "japan",
    "china",
    "india",
    "brazil",
    "italy",
    "spain",
    "netherlands",
    "sweden",
    "switzerland",
    "south-korea",
    "norway",
    "russia",
    "mexico",
    "south-africa",
  ];
  return (
    <DataState>
      <Suspense fallback={<>Loading...</>}>
        <Router>
          <Routes>
            {/* <Route path="/" element={<LandingPagev2 />} /> */}
            {/* <Route path="/" element={<LandingPagev2_AutomatedEmailWarmup />} /> */}
            {/* <Route path="/" element={<LandingPagev2_EmailWarmup />} /> */}
            {/* <Route path="/" element={<LandingPageNew />} /> */}
            {/* <Route
              path="/"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <LandingPageNewEmailwarmup />
                </Suspense>
              }
            /> */}
            {/* {process.env.REACT_APP_API_BASE_URL === "https://dashboard.automatedemailwarmup.com" && (
              <Route
                path="/"
                element={
                  <Suspense fallback={<LandingPageNewAutoEmailBuffer />}>
                    <LandingPagev2_AutomatedEmailWarmup />
                  </Suspense>
                }
              />
            )} */}
            {/* {process.env.REACT_APP_API_BASE_URL === "https://dashboard.emailwarmup.com" && (
              <Route
                path="/"
                element={
                  <Suspense fallback={<LandingPagev2_EmailWarmup />}>
                    <LandingPagev2_EmailWarmup />
                  </Suspense>
                }
              />)} */}
            {/*  */}
            {/* {(!process.env.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL === "") && (
              <Route
                path="/"
                element={
                  <Suspense fallback={<LandingPagev2_EmailWarmup />}>
                    <LandingPagev2_EmailWarmup />
                  </Suspense>
                }
              />)} */}
            {/*  */}
            <Route
              path="/"
              element={
                <Navigate to="/login" replace />
              }
            />

            {/* {process.env.REACT_APP_API_BASE_URL === "https://dashboard.emailwarmup.com" && (
              <Route path="/email-warmup" element={<Email_Warmup_LandingPage />} />
            )}
            {process.env.REACT_APP_API_BASE_URL === "https://dashboard.automatedemailwarmup.com" && (
              <Route path="/email-warmup" element={<AutoEmail_Warmup_LandingPage />} />
            )}
            {process.env.REACT_APP_API_BASE_URL === "https://dashboard.automatedemailwarmup.com" && (
              <Route path="/email-deliverility-consultant" element={<LandingPagev2_AutomatedEmailWarmup_Consultant />} />
            )}
            {process.env.REACT_APP_API_BASE_URL === "https://dashboard.emailwarmup.com" && (
              <Route path="/email-deliverility-consultant" element={<LandingPagev2_EmailWarmup_Consultant />} />
            )} */}


            {/* <Route path="/new" element={<LandingPageNew />} /> */}

            {/* <Route
              path="/email-warmup"
              element={<Email_Warmup_LandingPage />}
            /> */}


            {/* <Route path="*" element={<NotFound />} /> */}
            {/* <Route path="/login" element={<Login />} /> */}
            <Route path="/login" element={<Loginv2 />} />

            {/* <Route path="/test" element={<AppPassword />} />
            <Route path="/test2" element={<AppPasswordv2 />} /> */}
            {/* email-warmup ==> warm-up */}
            {/* <Route path="/warm-up" element={<LoadingPagev1 />} /> */}
            {/*  */}
            {/* <Route
              path="/automated-email-warm-up"
              element={<PricingPagev2 />}
            /> */}
            {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}

            {/* <Route path="/dashboard" element={<DashboardView />} /> */}
            <Route path="/dashboard" element={<Dashboardv3 />} />

            {/* <Route
              path="/check-email-for-spam-triggers"
              element={<EmailSpamChecker />}
            /> */}
            {/* <Route
              path="/link1"
              element={<Why_is_my_mailchimp_email_going_to_spam />}
            /> */}
            {/* <Route
              path="/email-deliverability-test"
              element={<EmailDeliverPage1 />}
            />
            <Route
              path="/email-deliverability-processing"
              element={<EmailDeliverPage2 />}
            />
            <Route
              path="/email-deliverability-report"
              element={<EmailDeliverPage3 />}
            /> */}

            {/* <Route
              path="/sendgrid-emails-going-to-spam"
              element={<Blogpagev3_Programatic_Seo />}
            /> */}
            {/* <Route path="/email-list/doctor" element={<EmailList />} />
            <Route path="/email-list/dentist" element={<EmailList />} />
            <Route path="/email-marketing/hotels" element={<EmailList />} />
            <Route path="/email-marketing/hvac" element={<EmailList />} /> */}

            {/* <Route path="/doctor/email-list" element={<EmailList />} />
            <Route path="/dentist/email-list" element={<EmailList />} />
            <Route path="/hotels/email-marketing" element={<EmailList />} />
            <Route path="/hvac/email-marketing" element={<EmailList />} /> */}
            {/* Dynamic Routes for Important Countries */}
            {/* {importantCountries.map((country) => (
              <Route path={`/email-list/${country}`} element={<EmailList />} />
            ))} */}
            {/* {importantCountries.map((country) => (
              <Route path={`/${country}/email-list`} element={<EmailList />} />
            ))} */}
            {/*  */}
            {/*  */}
            <Route path="/email-onboard" element={<EmailConnectStatusPage />} />
          </Routes>
        </Router>
      </Suspense>
    </DataState>
  );
};
export default ProjectRoutes;
